// --- IE 11 ---
BrowserDetect.init();

if (BrowserDetect.browser+BrowserDetect.version == 'Explorer11') {
  document.documentElement.classList.add('ie11');
}
// ---

window.EventBus = new Vue();

Vue.filter('dayMonth', (value) => {
  if (!value) return ''
  value = value.toString();
  
  const chunks = value.split('-');
  const year = chunks[0];
  const month = chunks[1];
  const day = chunks[2];

  return `${day}/${month}`;
});

Vue.filter('dateLong', (value) => {
  if (!value) return ''
  value = value.toString();

  const monthNames = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre'
  ];

  const chunks = value.split('-');
  const year = chunks[0];
  const month = chunks[1];
  const day = chunks[2];

  return `${day} ${monthNames[parseInt(month) - 1]} ${year}`;
});

Vue.component('date-list', {
  name: 'dateList',
  props: ['dates'],
  template: `
    <ul class="month">
      <li v-for="month in dates" :key="month.id" class="month__item">
        <h4 class="month__name">
          {{ month.name }}
        </h4>

        <ul class="date">
          <li v-for="day in month.days" :key="day.id" class="date__item">
            <date-item :data="day"></date-item>
          </li>
        </ul>
      </li>
    </ul>
  `,
  created() {
    EventBus.$on('SELECTED_DATE', this.resetSelected);
    EventBus.$on('ORDER_66', this.reset);
  },
  methods: {
    /**
     * Reset selected date item and hours list.
     */
    resetSelected(item) {
      const dateItems = this.$children
        .filter(child => child.$options.name === 'dateItem')
        .filter(component => component.data !== item);

      if (dateItems) {
        dateItems.forEach(component => component.isActive = false);
      }

      EventBus.$emit('RESET_HOURS');
    },

    /**
     * Reset dates list.
     */
    reset() {
      const dateItems = this.$children.filter(child => child.$options.name === 'dateItem');

      if (dateItems) {
        dateItems.forEach(component => component.isActive = false);
      }
    }
  }
});

Vue.component('date-item', {
  name: 'dateItem',
  props: ['data'],
  data() {
    return {
      isActive: false
    }
  },
  template: `
    <a href="#" :class="{ 'is-active': isActive }" class="date__link" @click="selectItem(data, $event)">
      <span class="date__day">
        {{ data.jour | dayMonth }}
      </span>
      <span class="date__spot">
        {{ data.lieu }}
      </span>
    </a>
  `,
  methods: {
    selectItem(item, evt) {
      evt.preventDefault();

      if (! this.isActive) {
        this.isActive = !this.isActive;
        EventBus.$emit('SELECTED_DATE', item);
      }
    }
  }
});

Vue.component('hour-list', {
  name: 'hourList',
  props: ['title', 'hours'],
  template: `
    <div class="time-col">
      <h4 class="time-heading">
        {{ title }}
      </h4>

      <ul class="time is-hovered">
        <li v-for="hour in hours" :key="hour.id" class="time__item">
          <hour-item :data="hour"></hour-item>
        </li>
      </ul>
    </div>
  `,
  created() {
    EventBus.$on('SELECTED_HOUR', this.resetSelected);
    EventBus.$on('ORDER_66', this.reset);
    EventBus.$on('RESET_HOURS', this.reset);
  },
  methods: {
    /**
     * Reset selected hour item.
     */
    resetSelected(item) {
      const dateItems = this.$children
        .filter(child => child.$options.name === 'hourItem')
        .filter(component => component.data !== item);

      if (dateItems) {
        dateItems.forEach(component => component.isActive = false);
      }
    },

    /**
     * Reset hours list.
     */
    reset() {
      const dateItems = this.$children.filter(child => child.$options.name === 'hourItem');

      if (dateItems) {
        dateItems.forEach(component => component.isActive = false);
      }
    }
  }
});

Vue.component('hour-item', {
  name: 'hourItem',
  props: ['data'],
  data() {
    return {
      isActive: false
    }
  },
  template: `
    <a href="#" :class="{ 'is-active': isActive }" class="time__link" @click="selectHour(data, $event)">
      {{ data.heure }}
    </a>
  `,
  methods: {
    selectHour(item, evt) {
      evt.preventDefault();

      if (! this.isActive) {
        this.isActive = !this.isActive;
        EventBus.$emit('SELECTED_HOUR', item);
      }
    }
  }
});

new Vue({
  el: '#app',
  data: {
    form: {
      name: '',
      firstname: '',
      email: '',
      phone: '',
      bi: '',
      bi2: ''
    },
    currentStep: 1,
    feedback: null,
    status: '',
    userData: [],
    dates: null,
    hours: null,
    hoursAM: null,
    hoursPM: null,
    dateSelected: null,
    hourSelected: null,
    spotSelected: null,
    addressSelected: null,
    isLoading: true,
    appointmentDisabled: true,
    showGates: true,
    takeAppointment: false,
    wantsToSell: false,
  },

  created() {
    EventBus.$on('SELECTED_DATE', this.saveDate);
    EventBus.$on('SELECTED_DATE', this.getAvailableHours);

    EventBus.$on('SELECTED_HOUR', this.saveHour);
    EventBus.$on('SELECTED_HOUR', this.prepareAppointment);

    EventBus.$on('ORDER_66', this.reset);

    EventBus.$on('RESET_HOURS', this.resetAppointmentBtn);
    EventBus.$on('RESET_HOURS', this.resetStateHoursList);
  },

  methods: {
    reset() {
      this.currentStep = 2;
      this.dates = null;
      this.hours = null;
      this.hoursAM = null;
      this.hoursPM = null;
      this.dateSelected = null;
      this.hourSelected = null;
      this.appointmentDisabled = true;
    },

    resetAppointmentBtn() {
      this.appointmentDisabled = true;
    },

    /**
     * Submit the from on step 1.
     */
    handleForm(evt) {
      evt.preventDefault();

      const formData = this.toFormData(this.form);

      axios.post('/php/gestionCompte.php', formData)
      .then(response => {
        if (response.data.status == 'valid') {
          this.currentStep = 2;
          this.userData = response.data.data[0];

          this.feedback = null;
        } else {
          // Reset form
          // this.resetForm();

          this.feedback = response.data.message;
          this.status = response.data.status;
        }
      })
      .catch(error => {
        console.error(error);
      });
    },

    /**
     * Prepare form data.
     */
    toFormData(obj) {
      const formData = new FormData();

      for (let key in obj) {
        formData.append(key, obj[key])
      }

      return formData;
    },

    resetForm() {
      const $form = document.querySelector('#register-form');
      const $labels = $form.querySelectorAll('label');
      const $inputs = $form.querySelectorAll('input');

      $labels.forEach(label => label.classList.remove('active'));
      $inputs.forEach(input => input.classList.remove('valid'));

      this.form.name = '';
      this.form.firstname = '';
      this.form.email = '';
      this.form.phone = '';
      this.form.bi = '';
      this.form.bi2 = '';
    },

    /**
     * Gate: choose to take an appointment and display dates list.
     */
    enterToTakeAppointment() {
      this.getAvailableDates();

      this.showGates = false;
      this.takeAppointment = true;
    },

    /**
     * Gate: choose to sell and proceed to step 3 (confirmation step).
     */
    enterToSell() {
      const confirmMessage = 'Vous êtes sur le point de déclarer vouloir vendre tous vos biens. Veuillez s\'il vous plaît confirmer votre choix :';

      if (window.confirm(confirmMessage)) {
        this.userData.email = this.form.email;
        this.userData.phone = this.form.phone;
        this.userData.bi2 = this.form.bi2;
        this.userData.address = this.addressSelected;
        this.wantsToSell = true;

        axios.post('/php/gestionRdv.php', { action: 'venteTotale', data: this.userData })
          .then(response => {
            this.currentStep = 3;
          })
          .catch(error => {
            console.error(error);
            this.currentStep = 1;
          });
      }
    },

    backToGates() {
      this.showGates = true;
      this.takeAppointment = false;
      this.appointmentDisabled = true;
      this.dates = null;
      this.dateSelected = null;
      this.hourSelected = null;
      this.hours = null;
      this.hoursAM = null;
      this.hoursPM = null;
      this.spotSelected = null;
      this.addressSelected = null;
    },

    setEventOnTimeList() {
      setTimeout(() => {
        // Scroll to hours
        $('html, body').animate({ scrollTop: $('#hours-wrapper').offset().top }, 2000);

        const $timeList = document.querySelectorAll('.time');
        $timeList.forEach(list => {
          list.addEventListener('mouseenter', this.onListEnter);
          list.addEventListener('mouseleave', this.onListLeave);
        });
      }, 0);
    },

    onListEnter(evt) {
      const $lists = Array.from(document.querySelectorAll('.time'));
      $lists.forEach(list => list.classList.remove('is-hovered'));

      evt.target.classList.add('is-hovered');
    },

    onListLeave(evt) {
      const $lists = Array.from(document.querySelectorAll('.time'));

      $lists.forEach(list => {
        list.classList.remove('is-hovered');
        list.classList.add('is-hovered');
      });
    },

    markSelectedTimeListAsActive() {
      setTimeout(() => {
        const $timeList = document.querySelectorAll('.time');
        const hourSelected = document.querySelector('.time .is-active');

        $timeList.forEach(list => {
          list.classList.remove('is-hovered');
          list.removeEventListener('mouseenter', this.onListEnter);
          list.removeEventListener('mouseleave', this.onListLeave);
        });

        hourSelected.parentElement.parentElement.classList.add('is-hovered');
      }, 0);
    },

    resetStateHoursList() {
      setTimeout(() => {
        const $timeList = document.querySelectorAll('.time');
        $timeList.forEach(list => list.classList.add('is-hovered'));
      });
    },

    /**
     * Retrieve dates data form API.
     */
    getAvailableDates() {
      axios.get('/php/gestionRdv.php?action=getListeJoursDisponibles')
        .then(response => {
          this.dates = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          console.error(error);
        });
    },

    /**
     * Retrieve hours data form API.
     */
    getAvailableHours(date) {
      axios.get('/php/gestionRdv.php?action=getListeHorairesDisponibles&dateJour='+date.jour)
        .then(response => {
          this.splitHoursByPeriods(response.data);
          this.setEventOnTimeList();
        })
        .catch(error => {
          console.error(error);
        });
    },

    splitHoursByPeriods(data) {
      const AMList = data.filter(hour => hour.periode === 'am');
      const PMList = data.filter(hour => hour.periode === 'pm');

      this.hours = data;
      this.hoursAM = AMList;
      this.hoursPM = PMList;
    },

    saveDate(date) {
      this.dateSelected = date.jour;

      this.saveAddress(date);
    },

    saveHour(hour) {
      this.hourSelected = hour.heure;
    },

    saveAddress(date) {
      this.addressSelected = date.adresse;
      this.spotSelected = date.lieu;
    },

    /**
     * Save global data for appointment.
     */
    prepareAppointment() {
      this.markSelectedTimeListAsActive();

      this.userData.date_rdv = this.dateSelected;
      this.userData.hour_rdv = this.hourSelected;
      this.userData.address = this.addressSelected;
      this.userData.email = this.form.email;
      this.userData.phone = this.form.phone;
      this.userData.bi2 = this.form.bi2;

      this.appointmentDisabled = false;
    },

    /**
     * Save appointment to Google Calendar.
     */
    setAppointment(evt) {
      evt.preventDefault();

      const confirmMessage = 'Vous souhaitez prendre rendez-vous. Veuillez s\'il vous plaît confirmer votre choix :';

      if (window.confirm(confirmMessage)) {
        axios.post('/php/gestionRdv.php', { action: 'ajouterRDV', data: this.userData })
          .then(response => {
            const data = response.data;

            if (data.status === 'indisponible') {
              this.feedback = data.message;
              this.status = data.status;

              // Reset dates / hours
              this.dates = null;
              this.hours = null;
              this.hoursAM = null;
              this.hoursPM = null;

              this.resetAppointmentBtn();

              this.getAvailableDates();
            } else {
              this.currentStep = 3;
            }
          })
          .catch(error => {
            console.error(error);
            EventBus.$emit('ORDER_66');
          });
      }
    },

    /**
     * DEBUG
    nextStep() {
      if (this.currentStep >= 3) {
        this.currentStep = 1;
      } else {
        this.currentStep++;
      }
    }
    */
  }
});
